import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;
    background-color: rgba(90, 145, 224, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Box = styled.div`
    width: 100%;
    max-width: 1216px;
    display: flex;
    align-items: center;
    gap: 20px;
    ${(props) =>
        props.isMobile
            ? `
        padding: 36px 50px 28px;
        flex-direction: column;
        justify-content: center;
    `
            : `
        padding: 27px 40px 27px;
        justify-content: space-between;
    `}
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    ${(props) =>
        props.isMobile
            ? `
        gap: 8px;
        align-items: center;
    `
            : `
        gap: 4px;
        align-items: flex-start;
    `}
`;

export const Title = styled.div`
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.84px;
    color: #1e1e1e;
    white-space: pre-line;
    ${(props) =>
        props.isMobile
            ? `
        font-size: 20px;
        text-align: center;
    `
            : `
        font-size: ${props.theme.language === "ko" ? "28px" : "26px"};
        text-align: left;
    `};
`;

export const Description = styled.div`
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.48px;
    color: #1e1e1e;

    white-space: pre-line;

    ${(props) =>
        props.isMobile
            ? `
        font-size: 14px;
        white-space: pre-line;
        text-align: center;
    `
            : `
        font-size: ${props.theme.language === "ko" ? "16px" : "14px"};
        text-align: left;
    `};
`;

export const ImageWrapper = styled.div`
    width: auto;
    min-width: ${(props) => props.minWidth};
    /* padding: 14px 16px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.08); */
`;

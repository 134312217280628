import React from "react";
import { Container, Box, BackgroundImageWrapper } from "components/style/layoutStyle";
import { TextTitle, TextTitleEn } from "components/style/textStyle";
import Image from "components/image/image";
import { IMG_MAIN_VISUAL } from "constants/media/images";
import ButtonBox from "./buttonBox";
import { useTranslation } from "react-i18next";
import { TopContentWrapper } from "../style";

const Desktop = () => {
    const { t, i18n } = useTranslation();

    return (
        <Container height={"700px"} padding={"0 40px"}>
            <BackgroundImageWrapper maxWidth={"100%"}>
                <Image filename={IMG_MAIN_VISUAL} alt="main top banner image" style={{ height: "100%", objectFit: "cover" }} />
            </BackgroundImageWrapper>
            <Box>
                <TopContentWrapper>
                    {i18n.language === "ko" ? (
                        <TextTitle type={i18n.language !== "ko" ? "44" : "52"} padding={"0 0 56px 0"} color={"#1e1e1e"}>
                            {t("MAIN_TITLE")}
                        </TextTitle>
                    ) : (
                        <TextTitleEn type={i18n.language !== "ko" ? "44" : "52"} padding={"0 0 56px 0"} color={"#1e1e1e"}>
                            {t("MAIN_TITLE")}
                        </TextTitleEn>
                    )}
                    <ButtonBox />
                </TopContentWrapper>
            </Box>
        </Container>
    );
};

export default Desktop;

import styled from "styled-components";

export const TopContentWrapper = styled.div`
    ${({ theme }) => `margin-bottom: ${theme.language === "ko" ? "30px" : "35px"}`};
`;

export const TopButtonBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: ${(props) => props.flexDirection};
    gap: ${(props) => props.gap || `16px`};

    ${({ isMobile }) => isMobile && `justify-content: center;`};
`;

// import {
//   IMG_ISO_MARK,
//   IMG_MAIN_GALLERY_ACE,
//   IMG_MAIN_GALLERY_BLANK,
//   IMG_MAIN_GALLERY_DONGHO,
//   IMG_MAIN_GALLERY_HW,
//   IMG_MAIN_GALLERY_LIWU,
//   IMG_MAIN_GALLERY_PASTOBOY,
//   IMG_MAIN_GALLERY_PIG,
//   IMG_MAIN_GALLERY_SEJONG,
//   IMG_MAIN_GALLERY_HYUNDAI_ALU,
//   IMG_MAIN_GALLERY_KOR_AM,
//   IMG_MAIN_GALLERY_BINGO,
//   IMG_MAIN_GALLERY_CENTRE,
//   IMG_MAIN_GALLERY_DAZN,
//   IMG_MAIN_GALLERY_HOBAK,
//   IMG_MAIN_GALLERY_HYUDO,
//   IMG_MAIN_GALLERY_INPLER,
//   IMG_MAIN_GALLERY_JINKETING,
//   IMG_MAIN_GALLERY_LATIGE,
//   IMG_MAIN_GALLERY_SEOUL_YESUL,
//   IMG_MAIN_GALLERY_SGPO,
//   IMG_MAIN_GALLERY_TURTLECREW,
//   IMG_MAIN_SPEECH_BUBBLE_MOBILE,
// } from "../media/images"

// import {
//   IC_MAIN_CHAT,
//   IC_MAIN_BULK,
//   IC_MAIN_GROUP,
//   IC_MAIN_FOLDER,
//   IC_MAIN_CLOUD,
//   IC_MAIN_BADGE,
//   IC_MAIN_SHIELD,
// } from "../media/icon"
// import {
//   IMG_MAIN_FEATURES,
//   IMG_MAIN_SPEECH_BUBBLE,
//   IMG_MAIN_API,
// } from "../media/images"

// // Main
// export const MAIN_TITLE = "계약의 디지털 전환\n전자계약 도뉴"

// export const MAIN_SERVICE = {
//   title: "복잡한 계약 업무,\n한번에 서명하고 손쉽게 관리하세요.",
//   title_mobile: "복잡한 계약 업무,\n한번에 서명하고\n손쉽게 관리하세요.",
//   desc: "매번 번거로웠던 계약 업무에 지치셨나요?\n도뉴가 효율적인 원스톱 솔루션을 제공합니다.",
//   link: {
//     type: "path",
//     title: "서비스 소개 확인하기",
//     url: "/service/features",
//   },
// }

// export const MAIN_FEATURES = {
//   img: IMG_MAIN_FEATURES,
//   title: "모든 계약 업무는\n도뉴에서 관리하세요.",
//   desc: "계약서 서명부터 관리까지\n단계별 필수 기능을 제공합니다.",
//   link: {
//     type: "path",
//     title: "기업 솔루션 확인하기",
//     url: "/service/solution",
//   },
// }

// export const MAIN_FEATURES_CARD = [
//   {
//     img: IC_MAIN_CHAT,
//     title: "채팅",
//     desc: "계약 참여자간 채팅으로\n실시간 협의가 가능합니다.",
//     desc_mobile: "계약 참여자간\n채팅으로 실시간\n협의가 가능합니다.",
//   },
//   {
//     img: IC_MAIN_FOLDER,
//     title: "폴더 관리",
//     desc: "수많은 계약서를 폴더 기능으로\n쉽게 관리할 수 있습니다.",
//     desc_mobile: "수많은 계약서를\n폴더 기능으로\n쉽게 관리할 수 있습니다.",
//   },
//   {
//     img: IC_MAIN_GROUP,
//     title: "그룹 관리",
//     desc: "그룹원들의 계약 현황을\n한눈에 파악하고 관리할 수 있습니다.",
//     desc_mobile: "그룹원들의 계약 현황을\n한눈에 파악하고\n관리할 수 있습니다.",
//   },
//   {
//     img: IC_MAIN_BULK,
//     title: "대량 전송",
//     desc: "한번의 클릭으로 동일한 계약을\n여러명에게 전송할 수 있습니다.",
//     desc_mobile:
//       "한번의 클릭으로\n동일한 계약을 여러명에게\n전송할 수 있습니다.",
//   },
// ]

// export const MAIN_RECOMMEND = {
//   img: IMG_MAIN_SPEECH_BUBBLE,
//   img_mobile: IMG_MAIN_SPEECH_BUBBLE_MOBILE,
//   // img_1: IMG_MAIN_PERSON_1,
//   // img_2: IMG_MAIN_PERSON_2,
//   // img_3: IMG_MAIN_PERSON_3,
//   title: "도뉴가\n해결해드립니다.",
//   desc: "도뉴에서 제공하는 모든 기능은\n실제로 계약 업무를 진행하는\n실무자들의 피드백에 기반합니다.\n\n불필요한 기능은 덜어내고\n오로지 효율성에 집중했습니다.",
//   link: {
//     type: "link",
//     title: "사용 가이드 확인하기",
//     url: "사용가이드",
//   },
// }

// export const MAIN_API = {
//   img: IMG_MAIN_API,
//   title: "별도 구축없이 API로\n필요한 기능만 사용하세요.",
//   desc: "기업 규모와 상관없이 \n계약 업무상 필요한 기능들로만\n편리하게 연동하실 수 있습니다.",
//   link: {
//     type: "link",
//     title: "API 연동/도입 문의하기",
//     url: "채널톡",
//   },
// }

// export const MAIN_LEGALITY_CARD = {
//   title: "전자계약 법적효력과\n보안은 걱정하지마세요.",
//   link: {
//     type: "path",
//     title: "법적효력/보안 더보기",
//     url: "/legality",
//   },
//   items: [
//     {
//       img: IC_MAIN_BADGE,
//       title: "보장된\n법적 효력",
//       desc: "별도의 공동인증서,공인인증없이\nPC/모바일을 통한 전자계약도\n서면 계약과 동일한\n법적효력을 보장받습니다.",
//     },
//     {
//       img: IC_MAIN_SHIELD,
//       title: "철저한 암호화 및\n문서 위변조 방지",
//       desc: "도뉴에서 체결된 모든 계약은\n검증된 블록체인 기술을 통해\n암호화되며 위변조 가능성을\n원천 차단합니다.",
//     },
//     {
//       img: IC_MAIN_CLOUD,
//       title: "이중 보안된\n클라우드 저장",
//       desc: "모든 계약은\n국제 표준 정보보호 인증을 받은\nAmazon cloud에 이중 보안되어\n안전하게 저장됩니다.",
//     },
//   ],
// }

// export const MAIN_ISO_BANNER = {
//   title: "ISO/IEC 27001 보안 인증 획득!",
//   desc: "국제표준 정보보호 인증인\nISO/IEC 27001을 획득한 도뉴의 보안 시스템이\n여러분의 계약과 개인정보를 안전하게 보호합니다.",
//   img: IMG_ISO_MARK,
// }

// export const MAIN_COMPANY_CARD = {
//   title: "도뉴 실제 고객사들의\n사례를 확인해보세요.",
//   link: {
//     type: "path",
//     title: "고객사례 확인하기",
//     url: "/references",
//   },
//   items: [
//     IMG_MAIN_GALLERY_ACE,
//     IMG_MAIN_GALLERY_BLANK,
//     // IMG_MAIN_GALLERY_PIG,
//     IMG_MAIN_GALLERY_PASTOBOY,
//     // IMG_MAIN_GALLERY_LIWU,
//     // IMG_MAIN_GALLERY_HW,
//     IMG_MAIN_GALLERY_SEJONG,
//     IMG_MAIN_GALLERY_DONGHO,
//     IMG_MAIN_GALLERY_HYUNDAI_ALU,
//     IMG_MAIN_GALLERY_KOR_AM,
//     IMG_MAIN_GALLERY_BINGO,
//     IMG_MAIN_GALLERY_CENTRE,
//     IMG_MAIN_GALLERY_DAZN,
//     IMG_MAIN_GALLERY_HOBAK,
//     IMG_MAIN_GALLERY_HYUDO,
//     IMG_MAIN_GALLERY_INPLER,
//     IMG_MAIN_GALLERY_JINKETING,
//     IMG_MAIN_GALLERY_LATIGE,
//     IMG_MAIN_GALLERY_SEOUL_YESUL,
//     IMG_MAIN_GALLERY_SGPO,
//     IMG_MAIN_GALLERY_TURTLECREW,
//   ],
// }

import {
    IMG_ISO_MARK,
    IMG_MAIN_GALLERY_ACE,
    IMG_MAIN_GALLERY_BLANK,
    IMG_MAIN_GALLERY_DONGHO,
    IMG_MAIN_GALLERY_HW,
    IMG_MAIN_GALLERY_LIWU,
    IMG_MAIN_GALLERY_PASTOBOY,
    IMG_MAIN_GALLERY_PIG,
    IMG_MAIN_GALLERY_SEJONG,
    IMG_MAIN_GALLERY_HYUNDAI_ALU,
    IMG_MAIN_GALLERY_KOR_AM,
    IMG_MAIN_GALLERY_BINGO,
    IMG_MAIN_GALLERY_CENTRE,
    IMG_MAIN_GALLERY_DAZN,
    IMG_MAIN_GALLERY_HOBAK,
    IMG_MAIN_GALLERY_HYUDO,
    IMG_MAIN_GALLERY_INPLER,
    IMG_MAIN_GALLERY_JINKETING,
    IMG_MAIN_GALLERY_LATIGE,
    IMG_MAIN_GALLERY_SEOUL_YESUL,
    IMG_MAIN_GALLERY_SGPO,
    IMG_MAIN_GALLERY_TURTLECREW,
    IMG_MAIN_SPEECH_BUBBLE_MOBILE
} from "../media/images";

import { IC_MAIN_CHAT, IC_MAIN_BULK, IC_MAIN_GROUP, IC_MAIN_FOLDER, IC_MAIN_CLOUD, IC_MAIN_BADGE, IC_MAIN_SHIELD } from "../media/icon";

import { IMG_MAIN_FEATURES, IMG_MAIN_SPEECH_BUBBLE, IMG_MAIN_API } from "../media/images";

export const MAIN_SERVICE = {
    title: "MAIN_SERVICE_TITLE",
    title_mobile: "MAIN_SERVICE_TITLE_MOBILE",
    desc: "MAIN_SERVICE_DESC",
    desc_mobile:"MAIN_SERVICE_DESC_MOBILE",
    link: {
        type: "path",
        title: "MAIN_SERVICE_LINK_TITLE",
        url: "/service/features"
    }
};

export const MAIN_FEATURES = {
    img: IMG_MAIN_FEATURES,
    title: "MAIN_FEATURES_TITLE",
    desc: "MAIN_FEATURES_DESC",
    link: {
        type: "path",
        title: "MAIN_FEATURES_LINK_TITLE",
        url: "/service/solution"
    }
};

export const MAIN_FEATURES_CARD = [
    {
        img: IC_MAIN_CHAT,
        title: "MAIN_FEATURES_CARD_1_TITLE",
        desc: "MAIN_FEATURES_CARD_1_DESC",
        desc_mobile: "MAIN_FEATURES_CARD_1_DESC_MOBILE"
    },
    {
        img: IC_MAIN_FOLDER,
        title: "MAIN_FEATURES_CARD_2_TITLE",
        desc: "MAIN_FEATURES_CARD_2_DESC",
        desc_mobile: "MAIN_FEATURES_CARD_2_DESC_MOBILE"
    },
    {
        img: IC_MAIN_GROUP,
        title: "MAIN_FEATURES_CARD_3_TITLE",
        desc: "MAIN_FEATURES_CARD_3_DESC",
        desc_mobile: "MAIN_FEATURES_CARD_3_DESC_MOBILE"
    },
    {
        img: IC_MAIN_BULK,
        title: "MAIN_FEATURES_CARD_4_TITLE",
        desc: "MAIN_FEATURES_CARD_4_DESC",
        desc_mobile: "MAIN_FEATURES_CARD_4_DESC_MOBILE"
    }
];

export const MAIN_RECOMMEND = {
    img: IMG_MAIN_SPEECH_BUBBLE,
    // img_mobile: IMG_MAIN_SPEECH_BUBBLE_MOBILE,
    title: "MAIN_RECOMMEND_TITLE",
    desc: "MAIN_RECOMMEND_DESC",
    link: {
        type: "link",
        title: "MAIN_RECOMMEND_LINK_TITLE",
        url: "사용가이드"
    }
};

export const MAIN_API = {
    img: IMG_MAIN_API,
    title: "MAIN_API_TITLE",
    desc: "MAIN_API_DESC",
    link: {
        type: "link",
        title: "MAIN_API_LINK_TITLE",
        url: "채널톡"
    }
};

export const MAIN_LEGALITY_CARD = {
    title: "MAIN_LEGALITY_CARD_TITLE",
    mobile_title: "MAIN_LEGALITY_CARD_TITLE_MOBILE",
    link: {
        type: "path",
        title: "MAIN_LEGALITY_CARD_LINK_TITLE",
        url: "/legality"
    },
    items: [
        {
            img: IC_MAIN_BADGE,
            title: "MAIN_LEGALITY_CARD_ITEMS_1_TITLE",
            desc: "MAIN_LEGALITY_CARD_ITEMS_1_DESC"
        },
        {
            img: IC_MAIN_SHIELD,
            title: "MAIN_LEGALITY_CARD_ITEMS_2_TITLE",
            desc: "MAIN_LEGALITY_CARD_ITEMS_2_DESC",
            desc_mobile : "MAIN_LEGALITY_CARD_ITEMS_2_DESC_MOBILE"
        },
        {
            img: IC_MAIN_CLOUD,
            title: "MAIN_LEGALITY_CARD_ITEMS_3_TITLE",
            desc: "MAIN_LEGALITY_CARD_ITEMS_3_DESC"
        }
    ]
};

export const MAIN_ISO_BANNER = {
    title: "MAIN_ISO_BANNER_TITLE",
    title_mobile:"MAIN_ISO_BANNER_TITLE_MOBILE",
    desc: "MAIN_ISO_BANNER_DESC",
    desc_mobile:"MAIN_ISO_BANNER_DESC_MOBILE",
    img: IMG_ISO_MARK
};

const MAIN_COMPANY_CARD_ITEMS = [
    IMG_MAIN_GALLERY_ACE,
    IMG_MAIN_GALLERY_BLANK,
    IMG_MAIN_GALLERY_PASTOBOY,
    IMG_MAIN_GALLERY_SEJONG,
    IMG_MAIN_GALLERY_DONGHO,
    IMG_MAIN_GALLERY_HYUNDAI_ALU,
    IMG_MAIN_GALLERY_KOR_AM,
    IMG_MAIN_GALLERY_BINGO,
    IMG_MAIN_GALLERY_CENTRE,
    IMG_MAIN_GALLERY_DAZN,
    IMG_MAIN_GALLERY_HOBAK,
    IMG_MAIN_GALLERY_HYUDO,
    IMG_MAIN_GALLERY_INPLER,
    IMG_MAIN_GALLERY_JINKETING,
    IMG_MAIN_GALLERY_LATIGE,
    IMG_MAIN_GALLERY_SEOUL_YESUL,
    IMG_MAIN_GALLERY_SGPO,
    IMG_MAIN_GALLERY_TURTLECREW
];

export const MAIN_COMPANY_CARD = {
    title: "MAIN_COMPANY_CARD_TITLE",
    title_mobile:"MAIN_COMPANY_CARD_TITLE_MOBILE",
    link: {
        type: "path",
        title: "MAIN_COMPANY_CARD_LINK_TITLE",
        url: "/references"
    },
    items: MAIN_COMPANY_CARD_ITEMS
};

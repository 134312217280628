import React, { useMemo } from "react";
import ResponsiveMedia from "utils/responsive_media";

import { Video, VideoBox, VideoWrapper, VideoBarWrapper } from "components/style/videoStyle";
import VideoMP4 from "images/video/video_landing_contract_main_ko.mp4";
import VideoOGV from "images/video/video_landing_contract_main_ko.mp4";
import VideoWEBM from "images/video/video_landing_contract_main_ko.mp4";

import VideoMP4_EN from "images/video/video_landing_contract_main_en.mp4";
import VideoOGV_EN from "images/video/video_landing_contract_main_en.mp4";
import VideoWEBM_EN from "images/video/video_landing_contract_main_en.mp4";

import { BarContainer, BarButtonWrapper, BarButton } from "./style";
import { useTranslation } from "react-i18next";

const ServiceVideo = () => {
    const { i18n } = useTranslation();

    const { isMobile } = ResponsiveMedia();

    const video = useMemo(() => {
        if (i18n.language === "en")
            return {
                mp4: VideoMP4_EN,
                ocv: VideoOGV_EN,
                webm: VideoWEBM_EN
            };

        return {
            mp4: VideoMP4,
            ocv: VideoOGV,
            webm: VideoWEBM
        };
    }, [i18n.languages]);

    return (
        <VideoBox>
            <VideoWrapper>
                <VideoBarWrapper>
                    <BarContainer mobile={isMobile === "true"}>
                        <BarButtonWrapper>
                            <BarButton color={"#ed6a5f"} />
                            <BarButton color={"#f5bd50"} />
                            <BarButton color={"#62c354"} />
                        </BarButtonWrapper>
                    </BarContainer>
                </VideoBarWrapper>
                <Video playsInline style={{ borderRadius: "0 0 10px 10px" }}>
                    <source src={video.mp4} type="video/mp4" />
                    <source src={video.ocv} type="video/ogv" />
                    <source src={video.webm} type="video/webm" />
                </Video>
            </VideoWrapper>
        </VideoBox>
    );
};

export default ServiceVideo;

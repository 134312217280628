import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import Image from "components/image/image";

import { CardTable, CardTableRow, CardTableCol, CardTitle, CardDesc, CardIconWrapper } from "./style";
import { useTranslation } from "react-i18next";

const Card = ({ data }) => {
    const { isMobile } = ResponsiveMedia();
    const { t } = useTranslation();

    return (
        <CardTable>
            <CardTableRow xs={isMobile === "true" ? "2" : "4"} isMobile={isMobile === "true"}>
                {data.map((item, index) => {
                    return (
                        <CardTableCol key={index} mobile={isMobile}>
                            <CardIconWrapper>
                                <Image filename={item.img} alt={t(item.title)} />
                            </CardIconWrapper>
                            <CardTitle fontSize={isMobile === "true" ? "20px" : "24px"} last={index + 1 === data.length}>
                                {t(item.title)}
                            </CardTitle>
                            <CardDesc fontSize={isMobile === "true" ? "16px" : "18px"}>{isMobile && item.desc_mobile ? t(item.desc_mobile) : t(item.desc)}</CardDesc>
                        </CardTableCol>
                    );
                })}
            </CardTableRow>
        </CardTable>
    );
};

export default Card;

import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import Mobile from "./mobile";
import Desktop from "./desktop";

const TopBanner = () => {
    const { isMobile } = ResponsiveMedia();
    return <>{isMobile === "true" ? <Mobile /> : <Desktop />}</>;
};

export default TopBanner;

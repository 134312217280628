import React from "react";
import { RedSquareButton, OutlineButton } from "components/button";
import MoveToSignUp from "utils/move_to_sign_up";
import OpenLink from "utils/open_link";
import { TopButtonBox } from "../style";
import { checkIsLogin } from "utils/common";
import moveToDashboard from "utils/move_to_dashboard";
import { useTranslation } from "react-i18next";

const ButtonBox = () => {
    const isLogin = checkIsLogin();

    const { t } = useTranslation();

    return (
        <TopButtonBox>
            <RedSquareButton
                width={"210px"}
                height={"54px"}
                fontSize={"20px"}
                margin={"0 16px 0 0"}
                text={isLogin ? t("LOGIN_TITLE") : t("PROMOTION_LEFT_TITLE")}
                onClickEvent={() => (isLogin ? moveToDashboard() : MoveToSignUp("회원가입", "무료 체험 시작"))}
            />
            <OutlineButton
                width={"210px"}
                height={"54px"}
                fontSize={"20px"}
                text={isLogin ? t("CONTACT_US_TITLE") : t("PROMOTION_RIGHT_TITLE")}
                color={"#cd001a"}
                borderColor={"#cd001a"}
                hColor={"#fff"}
                bgColor={"transparent"}
                hBgColor={"#cd001a"}
                aColor={"#fff"}
                aBgColor={"#a20b0b"}
                onClickEvent={() => OpenLink("사용자문의", "도입문의", "채널톡")}
            />
        </TopButtonBox>
    );
};

export default ButtonBox;

import React from "react";
import { useTranslation } from "react-i18next";
import ResponsiveMedia from "utils/responsive_media";
import DescriptionWidhImage from "../../parts/description/descriptionWithImage";

const IntroRecomend = ({ desc }) => {
    return <DescriptionWidhImage data={desc} height={"624px"} bgColor={"#faf7ef"} reverse />;
};

export default IntroRecomend;

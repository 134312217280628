import React from "react";
import ResponsiveMedia from "utils/responsive_media";

import { Container, Box } from "components/style/layoutStyle";
import { TextBold } from "components/style/textStyle";
import TextButton from "components/button/textButton";
import { MAIN_LEGALITY_CARD } from "constants/data/main";
import Card from "./card";
import { useTranslation } from "react-i18next";

const CardLegal = () => {
    const { isMobile } = ResponsiveMedia();
    const { t, i18n } = useTranslation();

    return (
        <Container padding={isMobile === "true" ? "80px 20px" : "130px 40px"} height={"auto"} bgColor={"#f9f9f9"}>
            <Box>
                <div style={{ width: "100%" }}>
                    <Box
                        padding={isMobile && i18n.language !== "ko" ? "0px" : "0 40px"}
                        justifycontent={isMobile === "true" ? "center" : "space-between"}
                        alignitems={isMobile === "true" ? "center" : "flex-end"}
                        flexDirection={isMobile === "true" ? "column" : ""}
                        gap={isMobile === "true" ? "20px 0" : ""}
                    >
                        <TextBold type={isMobile === "true" ? "26" : i18n.language === "ko" ? "36" : "40"} textAlign={isMobile === "true" ? "center" : "left"}>
                            {t(isMobile ? MAIN_LEGALITY_CARD.mobile_title : MAIN_LEGALITY_CARD.title)}
                        </TextBold>
                        <TextButton justifycontent={"center"} data={MAIN_LEGALITY_CARD.link} />
                    </Box>
                    <Box>
                        <Card data={MAIN_LEGALITY_CARD.items} />
                    </Box>
                </div>
            </Box>
        </Container>
    );
};

export default CardLegal;

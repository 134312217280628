import styled from "styled-components";

export const BarContainer = styled.div`
    width: 100%;
    background-color: #1e1e1e;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    ${props => props.mobile? `
        height: 12px;
        > div {
            padding: 0 5px;
            >div {
                width: 5px;
                height: 5px;
            }
        }
    `:`
        height: 24px;
        > div {
            padding: 0 10px;
            > div {
                width: 10px;
                height: 10px;
            }
        }
    `}
`

export const BarButtonWrapper = styled.div`
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
`

export const BarButton = styled.div`
    border-radius: 50%;
    background-color: ${props => props.color};
`
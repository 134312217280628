import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import { MAIN_SERVICE } from "constants/data/main";
import Description from "../../parts/description/description";
import ServiceVideo from "./serviceVideo";
import { Container } from "../../../components/style/layoutStyle";
import { useTranslation } from "react-i18next";

const IntroService = () => {
    const { t, i18n } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    return (
        <Container padding={isMobile === "true" ? "80px 20px" : "130px 40px"} height={"auto"} flexDirection={"column"} gap={isMobile === "true" ? "40px" : "80px"}>
            <Description
                type={isMobile === "true" ? "normal" : "main"}
                mobile={isMobile}
                title={isMobile === "true" ? t(MAIN_SERVICE.title_mobile) : t(MAIN_SERVICE.title)}
                desc={isMobile === "true" ? t(MAIN_SERVICE.desc_mobile) : t(MAIN_SERVICE.desc)}
                link={MAIN_SERVICE.link}
                titleLineHeight={isMobile && i18n.language !== "ko" ? "1.14" : null}
                descLineHeight={isMobile && i18n.language !== "ko" ? "1.25" : null}
            />
            <ServiceVideo />
        </Container>
    );
};

export default IntroService;

import React from "react";
import { Container, MobileBox, BackgroundImageWrapper } from "components/style/layoutStyle";
import { TextTitle, TextTitleEn } from "components/style/textStyle";
import Image from "components/image/image";
import ButtonBox from "./buttonBox";
import { IMG_MAIN_VISUAL_MOBILE } from "constants/media/images";
import { TopContentWrapper } from "../style";
import { useTranslation } from "react-i18next";

const Mobile = () => {
    const { t, i18n } = useTranslation();

    return (
        <Container height={"569px"} alignitems={"start"} justifycontent={"center"}>
            <BackgroundImageWrapper maxWidth={"100%"}>
                <Image filename={IMG_MAIN_VISUAL_MOBILE} alt="main top banner image" style={{ height: "569px" }} />
            </BackgroundImageWrapper>
            <MobileBox>
                <TopContentWrapper>
                    {i18n.language === "ko" ? (
                        <TextTitle type={"32"} textAlign={"center"} padding={"70px 0 40px"} color={"#1e1e1e"} lineHeight={1.13}>
                            {t("MAIN_TITLE")}
                        </TextTitle>
                    ) : (
                        <TextTitleEn type={"32"} textAlign={"center"} padding={"70px 0 40px"} color={"#1e1e1e"} lineHeight={1.13}>
                            {t("MAIN_TITLE")}
                        </TextTitleEn>
                    )}
                    <ButtonBox />
                </TopContentWrapper>
            </MobileBox>
        </Container>
    );
};

export default Mobile;

import React from "react";
import ResponsiveMedia from "utils/responsive_media";

import { Container, Box } from "components/style/layoutStyle";

import Card from "../../parts/card";

import DescriptionWidhImage from "../../parts/description/descriptionWithImage";
import { useTranslation } from "react-i18next";

const IntroFeatures = ({ desc, card }) => {
    const { isMobile } = ResponsiveMedia();
    const { i18n } = useTranslation();

    return (
        <Container height={"auto"} justifycontent={"center"} padding={isMobile === "true" ? "80px 0 90px" : "0 0 140px"} flexDirection={"column"}>
            <DescriptionWidhImage data={desc} padding={"100px 0 30px"} textToTop />
            <Box margin={"-80px 0 0"}>
                <Card
                    data={card}
                    justifyContent={isMobile === "true" ? "center" : "space-around"}
                    alginitems={"flex-start"}
                    iconSize={"70px"}
                    colCount={isMobile === "true" ? "2" : "4"}
                    titleSize={i18n.language === "ko" ? "20px" : "22px"}
                />
            </Box>
        </Container>
    );
};

export default IntroFeatures;

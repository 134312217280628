import React from "react";
import Image from "components/image/image";
import ResponsiveMedia from "utils/responsive_media";
import { Box, Container, ContentWrapper, Description, ImageWrapper, Title } from "./styles";
import { useTranslation } from "react-i18next";

const ISOBanner = ({ data }) => {
    const { isMobile } = ResponsiveMedia();
    const { t } = useTranslation();

    return (
        <Container>
            <Box isMobile={isMobile}>
                <ContentWrapper isMobile={isMobile}>
                    <Title isMobile={isMobile}>{isMobile ? t(data.title_mobile) : t(data.title)}</Title>
                    <Description isMobile={isMobile}>{isMobile ? t(data.desc_mobile) : t(data.desc)}</Description>
                </ContentWrapper>
                <ImageWrapper minWidth={isMobile ? "237px" : "277px"}>
                    <Image filename={data.img} alt={t(data.title)} style={{ margin: "0" }} />
                </ImageWrapper>
            </Box>
        </Container>
    );
};

export default ISOBanner;

import * as React from "react";
import Seo from "components/seo";
import Main from "organisms/main";
import LayoutBanner from "../templates/layoutBanner";

import { MAIN_FEATURES, MAIN_FEATURES_CARD } from "constants/data/main";
import { MAIN_RECOMMEND } from "constants/data/main";
import { MAIN_API } from "constants/data/main";
import { MAIN_META, MAIN_META_EN } from "../constants/data/meta_data";
import { MAIN_COMPANY_CARD } from "constants/data/main";
import { MAIN_ISO_BANNER } from "../constants/data/main";

import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const IndexPage = () => {
    const { i18n } = useTranslation();

    const seoData = useMemo(() => {
        if (i18n.language === "en") return MAIN_META_EN;

        return MAIN_META;
    }, [i18n.language]);

    return (
        <LayoutBanner>
            <Seo title={seoData.title} description={seoData.description} canonical={seoData.canonical} kakaoKeyword={seoData.kakao} disableDefault />
            <Main features={MAIN_FEATURES} features_card={MAIN_FEATURES_CARD} recomend={MAIN_RECOMMEND} api={MAIN_API} iso={MAIN_ISO_BANNER} comp_card={MAIN_COMPANY_CARD} />
        </LayoutBanner>
    );
};

export default IndexPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
